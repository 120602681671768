<template>
  <div>
    <div class="row my-3">
      <h1 class="col-sm-12 text-right">Solicitudes</h1>
    </div>

    <DataGrid :configuracion="datagrid_conf" :data="solicitudes" @ready="buscar_btns">
      <button class="btn btn-warning" @click="obtener_solicitudes">Recargar</button>
    </DataGrid>

    <Info v-if="ver_info" :solicitud_id="solicitud_id" @close="ver_info=false"/>
  </div>
</template>

<script>
import DataGrid from '@/components/DataGridV3';

import Info from './Solicitud/Info';

import api from '@/apps/fyc/api/solicitudes';

export default {
  components: {
    DataGrid, Info
  },
  data() {
    return {
      datagrid_conf: {
        name: 'solicitudes',
        selector: false,
        cols: {
          solicitud_id: 'ID',
          estatus: 'Estatus',
          created_at: 'Fecha',
          otros: ''
        },
        mutators: {
          created_at(val,row,vue) {
            return vue.$moment(val).format('ddd DD MMM YYYY - HH:mm')
          },
          otros(val,row,vue) {
            return '<button class="btn btn-primary solicitud_btn" data-id="'+row.id+'">Revisar</button>';
          }
        }
      },
      solicitudes: [],
      ver_info: false,
      solicitud_id: null
    }
  },
  mounted() {
    this.obtener_solicitudes();
  },
  methods: {
    async obtener_solicitudes() {
      try {
        this.solicitudes = (await api.obtener_solicitudes()).data;
      }catch(e) {
        this.$log.info('error',e);
        this.$helper.showAxiosError(e,'Error');
      }
    },
    buscar_btns() {
      document.querySelectorAll('.solicitud_btn').forEach(btn => {
        btn.removeEventListener('click', this.acciones);
        btn.addEventListener('click', this.acciones);
      });
    },
    acciones(e) {
      this.solicitud_id = e.currentTarget.dataset.id;
      this.ver_info = true;
    }
  }
}
</script>